import { useState } from 'react';
import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { buildClient } from '@datocms/cma-client-browser';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { HeadingSmall } from 'components/UI/Headings';
import { LightButton } from 'components/UI/Buttons';

import { Input, Textarea, Select } from 'styles/elements';

import { Content, Description, Label, Error, Form, MainFields } from './styles';

const showToastMessage = () => {
  toast.success('Дякуємо за реєстрацію!', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'dark',
  });
};

const RegistrationTemplate = ({ data, pageContext }) => {
  const {
    datoCmsRegistrationPage: pageData,
    allDatoCmsCourse: { edges: courses },
  } = data;
  const [formData, setFormData] = useState({
    firstName: '',
    secondName: '',
    gender: '',
    email: '',
    course: '–',
    homeSchool: '',
    currentLocation: '',
    message: '',
  });
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const {
    title,
    pageInfo,
    firstNameLabel,
    firstNamePlaceholder,
    firstNameError,
    secondNameLabel,
    secondNamePlaceholder,
    secondNameError,
    // genderLabel,
    // genderList,
    // genderError,
    emailLabel,
    emailPlaceholder,
    emailError,
    courseLabel,
    coursePlaceholder,
    courseError,
    homeSchoolLabel,
    homeSchoolPlaceholder,
    homeSchoolError,
    currentLocationLabel,
    currentLocationPlaceholder,
    currentLocationError,
    messageLabel,
    messagePlaceholder,
    messageError,
    submitButtonText,
  } = pageData;

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addStudentCMS = async () => {
    const client = buildClient({ apiToken: '96cd91ef5af792335a1f5195219004' });

    try {
      await client.items.create({
        item_type: {
          type: 'item_type',
          id: '874924',
        },
        full_name: `${formData.firstName} ${formData.secondName}`,
        gender: formData.gender,
        email: formData.email,
        course: formData.course,
        home_school: formData.homeSchool,
        current_location: formData.currentLocation,
        student_status: 'New',
        registration_message: formData.message,
      }).then(() => {
        showToastMessage();
        reset({
          firstName: '',
          secondName: '',
          gender: '',
          email: '',
          course: '–',
          homeSchool: '',
          currentLocation: '',
          message: '',
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PageWrapper pageData={pageContext}>
      <ToastContainer />
      <MainContainer>
        <Content>
          <HeadingSmall as="h1">{title}</HeadingSmall>
          <Description>
            <StructuredText data={pageInfo} />
          </Description>
          <Form onSubmit={handleSubmit(addStudentCMS)}>
            <MainFields>
              <Label htmlFor="firstName">
                <p>{firstNameLabel}</p>
                <Input
                  {...register('firstName', { required: true })}
                  aria-invalid={errors.firstName ? 'true' : 'false'}
                  defaultValue={formData.firstName}
                  placeholder={firstNamePlaceholder}
                  onChange={handleChange}
                  className={errors.homeSchool && 'error'}
                />
                {errors.firstName?.type === 'required' && (
                  <Error role="alert">{firstNameError}</Error>
                )}
              </Label>
              <Label htmlFor="secondName">
                <p>{secondNameLabel}</p>
                <Input
                  {...register('secondName', { required: true })}
                  aria-invalid={errors.secondName ? 'true' : 'false'}
                  defaultValue={formData.secondName}
                  placeholder={secondNamePlaceholder}
                  onChange={handleChange}
                  className={errors.homeSchool && 'error'}
                />
                {errors.secondName?.type === 'required' && (
                  <Error role="alert">{secondNameError}</Error>
                )}
              </Label>
            </MainFields>

            <Label htmlFor="email">
              <p>{emailLabel}</p>
              <Input
                {...register('email', { required: true })}
                aria-invalid={errors.email ? 'true' : 'false'}
                type="email"
                defaultValue={formData.email}
                placeholder={emailPlaceholder}
                onChange={handleChange}
                className={errors.homeSchool && 'error'}
              />
              {errors.email?.type === 'required' && (
                <Error role="alert">{emailError}</Error>
              )}
            </Label>
            <Label htmlFor="course">
              <p>{courseLabel}</p>
              <Select
                {...register('course', { required: true })}
                aria-invalid={errors.course ? 'true' : 'false'}
                defaultValue={formData.course}
                placeholder={coursePlaceholder}
                onChange={handleChange}
                className={errors.homeSchool && 'error'}
              >
                <option value="–">–</option>
                {courses.map((course, id) => {
                  const { courseName } = course.node;

                  return (
                    <option key={id} value={courseName}>
                      {courseName}
                    </option>
                  );
                })}
              </Select>
              {errors.course?.type === 'required' && (
                <Error role="alert">{courseError}</Error>
              )}
            </Label>
            <Label htmlFor="homeSchool">
              <p>{homeSchoolLabel}</p>
              <Input
                {...register('homeSchool', { required: true })}
                aria-invalid={errors.homeSchool ? 'true' : 'false'}
                defaultValue={formData.homeSchool}
                placeholder={homeSchoolPlaceholder}
                onChange={handleChange}
                className={errors.homeSchool && 'error'}
              />
              {errors.homeSchool?.type === 'required' && (
                <Error role="alert">{homeSchoolError}</Error>
              )}
            </Label>
            <Label htmlFor="currentLocation">
              <p>{currentLocationLabel}</p>
              <Input
                {...register('currentLocation', { required: true })}
                aria-invalid={errors.currentLocation ? 'true' : 'false'}
                defaultValue={formData.currentLocation}
                placeholder={currentLocationPlaceholder}
                onChange={handleChange}
                className={errors.homeSchool && 'error'}
              />
              {errors.currentLocation?.type === 'required' && (
                <Error role="alert">{currentLocationError}</Error>
              )}
            </Label>
            <Label htmlFor="message">
              <p>{messageLabel}</p>
              <Textarea
                {...register('message', { required: true })}
                aria-invalid={errors.message ? 'true' : 'false'}
                defaultValue={formData.message}
                placeholder={messagePlaceholder}
                onChange={handleChange}
                className={errors.homeSchool && 'error'}
              />
              {errors.message?.type === 'required' && (
                <Error role="alert">{messageError}</Error>
              )}
            </Label>

            <LightButton type="submit">{submitButtonText}</LightButton>
          </Form>
        </Content>
      </MainContainer>
    </PageWrapper>
  );
};

export default RegistrationTemplate;

export const query = graphql`
  query RegistrationQuery($locale: String!) {
    datoCmsRegistrationPage(locale: { eq: $locale }) {
      title
      pageInfo {
        value
      }
      firstNameLabel
      firstNamePlaceholder
        firstNameError
      secondNameLabel
      secondNamePlaceholder
        secondNameError
      genderLabel
      genderList
        genderError
      emailLabel
      emailPlaceholder
        emailError
      courseLabel
      coursePlaceholder
        courseError
      homeSchoolLabel
      homeSchoolPlaceholder
        homeSchoolError
      currentLocationLabel
      currentLocationPlaceholder
        currentLocationError
      messageLabel
      messagePlaceholder
        messageError
      submitButtonText
    }
    allDatoCmsCourse(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          courseName
        }
      }
    }
  }
`;
