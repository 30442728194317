import styled from 'styled-components';

import { media } from '../../styles/mixins';

const Content = styled.section`
  max-width: 50rem;
  width: 100%;

  &&& {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Description = styled.div`
  font-size: 1.125rem;

  a {
    text-decoration: underline;
  }
`;

const Label = styled.label`
  input,
  textarea {
    padding: 1rem;
  }

  textarea {
    min-height: 10rem;
  }

  select {
    height: 3.125rem;
    overflow: hidden !important; //overriding important useragent styles
    text-indent: 0.5rem;
  }

  p:not([role=alert]) {
    font-size: 1.125rem;
    line-height: 1.33;
    margin-bottom: 0.25rem;
  }
`;

const Error = styled.p`
  font-size: 0.9rem;
  color: var(--color-error);
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--gap);
  padding: 2.625rem 4rem;
  border-radius: 1rem;
  background-color: var(--color-dark-02);
  margin-top: 4rem;

  ${media.mobileM`
    padding: 1.5rem;
    margin-top: 1.5rem;
  `}

  button[type=submit] {
    width: fit-content;
    margin-left: auto;

    ${media.mobileM`
      width: 100%;
    `}
  }
`;

const MainFields = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap);

  ${media.mobileM`
    grid-template-columns: 1fr;
  `}
`;

export { Content, Description, Label, Error, Form, MainFields };
